<template>
  <div class="roundcard-content">
    <div class="roundcard">
      <div class="hairstyle" v-if="categoryList.length>0" v-for="item in categoryList">
        <p style="margin-bottom:10px">{{item.categoryName}}</p>
        <div class="hand" v-if="item.roundCardList" v-for="rc in item.roundCardList">
          <p>
            <span class="hand-id">{{rc.employeeNo}}</span>
          </p>
          <el-dropdown style="text-align:center;width:100%;margin-top:5px" @command="playingCards">
              <span class="el-dropdown-link" style="color:#fff">
               {{rc.employeeName}}
                <i class="el-icon-arrow-down el-icon--right"></i>
              </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="'a,'+rc.id">轮牌</el-dropdown-item>
              <el-dropdown-item :command="'b,'+rc.id">下牌</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <img src="../../assets/login-bg.png" alt/>
        </div>
        <div class="kong" style="cursor: pointer" @click="toAdd(item.id)">
          <span class="el-icon-plus" style="font-size:22px;"></span>
        </div>
      </div>
      <div>
        <el-dialog title="添加发型师" :visible.sync="addVisible" width="30%">
          <el-checkbox-group v-model="employeeIds">
            <el-checkbox v-for="item in employeeList" :label="item.id" :key="item.id">{{item.nickName}}</el-checkbox>
          </el-checkbox-group>
          <span slot="footer" class="dialog-footer">
            <el-button style="background-color:#555555;color:#fff" size="medium "
                       @click="saveRoundCard">确认添加</el-button>
          </span>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "",
    data() {
      return {
        addVisible: false,

        checked1: false,
        checked2: true,


        categoryId: '',
        employeeIds: [],

        employeeList: [],
        categoryList: [],
      };
    },
    created() {
      this.getRoundCardList()
    },
    methods: {
      async getRoundCardList() {
        let res = await this.$get("/admin/getRoundCardList")
        if (res.code == 200) {
          this.categoryList = res.categoryList
        }
      },

      toAdd(categoryId) {
        this.categoryId = categoryId
        this.getEmployeeList()
      },

      async getEmployeeList() {
        let data={
          categoryId: this.categoryId,
          isDel: '0'
        }
        let res = await this.$get("/admin/getEmployeesByCategoryId",data )
        if (res.code == 200) {
          this.employeeList = res.list
          if (this.employeeList.length > 0) {
            this.addVisible = true
          } else {
            this.$message.error("该轮牌员工已全部添加")
          }
        }
      },
      async saveRoundCard() {
        let data = {
          employeeIds: JSON.stringify(this.employeeIds),
          categoryId: this.categoryId,
        };
        let res = await this.$post("/admin/saveRoundCard", data)

        if (res.code == 200) {
          this.addVisible = false
          this.getRoundCardList()
          this.$message.success(res.message);
        } else {
          this.$message.error(res.message);
        }
      },

      async playingCards(command) {
        let type = command.substring(0, 1)
        let id = command.slice(2)
        if (type == 'b') {
          let res = await this.$post("/admin/remove_bbiRoundCard", {id: id})
          if (res.code == 200) {
            this.getRoundCardList()
            this.$message.success(res.message)
          } else {
            this.$message.error(res.message)
          }
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .roundcard-content {
    width: 100%;
    height: 100%;
    .roundcard {
      height: 100%;
      margin-top: 20px;
      margin-left: 20px;
    }
  }

  .hairstyle {
    margin-left: 10px;
    position: relative;
    height: 200px;
    p {
      width: 50px;
      height: 50px;
      line-height: 50px;
      color: #b5b5b5;
    }
    .hand,
    .kong {
      float: left;
      width: 100px;
      height: 150px;
      border-radius: 5px;
      margin-bottom: 10px;
    }
    .kong {
      background-color: #ececec;
      text-align: center;
      line-height: 150px;
      color: #d2d2d2;
      margin-left: 10px;
    }
    .hand {
      background-color: #5dae72;
      color: #fff;
      margin-right: 10px;
      div {
        position: relative;
      }
      p {
        width: 100px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        color: #fff;
        .hand-id {
          width: 45px;
          height: 20px;
          background-color: #437e52;
          font-size: 14px;
          display: inline-block;
          border-radius: 5px;
        }
      }
      img {
        width: 100px;
        height: 105px;
        border-radius: 5px;
        margin-top: 5px;
      }
    }
  }
</style>
